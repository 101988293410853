const translations = {
    en: {
      fact_checked: "Fact Checked",
      article: "Article",
      source_link: "Source Link",
      created_by: "Created By",
      comments: "Comments",
      no_comments_yet: "No Comments Yet",
      read_more: "Read More",
      related_fact_checked: "Related Fact Checked",
      related_articles: "Related Articles",
      hang_out: "Let's hang out on social",
      footer_donate:
        "Support Oumuamua News Inc., efforts to continue to proliferate true news about humanity.",
      donate: "Donate",
    },
    he: {
      fact_checked: "מבוצע בדיקה",
      article: "מאמר",
      source_link: "קישור מקור",
      created_by: "נוצר על ידי",
      comments: "תגובות",
      no_comments_yet: "אין תגובות עדיין",
      read_more: "קרא עוד",
      related_fact_checked: "מבוצע בדיקה קשור",
      related_articles: "מאמרים קשורים",
      hang_out: "لבוא ניפגש ברשתות החברתיות",
      footer_donate:
        "תמכו במאמצי Oumuamua News Inc. להמשיך להפיץ חדשות אמיתיות על האנושות.",
      donate: "תרום",
    },
    ar: {
      fact_checked: "تم التحقق من الحقائق",
      article: "مقال",
      source_link: "رابط المصدر",
      created_by: "تم إنشاؤه بواسطة",
      comments: "تعليقات",
      no_comments_yet: "لا توجد تعليقات بعد",
      read_more: "اقرأ المزيد",
      related_fact_checked: "تم التحقق من الحقائق ذات الصلة",
      related_articles: "مقالات ذات صلة",
      hang_out: "لنلتقي على وسائل التواصل الاجتماعيالتسكع",
      footer_donate:
        "ادعم جهود Oumuamua News Inc. للاستمرار في نشر أخبار حقيقية عن البشرية.",
      donate: "تبرع",
    },
  };
  
  export default translations;
  
