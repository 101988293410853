import React, { createContext, useContext, useEffect, useState } from 'react';
import translations from './Translations';


const I18nContext = createContext();

export const I18nProvider = ({ children }) => {
  const languageValue = localStorage.getItem('news_language');
  const [language, setLanguage] = useState(languageValue ? languageValue : 'en');
  const [direction, setDirection] = useState("ltr");

  useEffect(()=>{
    localStorage.setItem("news_language",language)
    if(language=="en"){
      setDirection("ltr")
    }
    if(language=="he"){
      setDirection("rtl")
    }
    if(language=="ar"){
      setDirection("rtl")
    }

  },[language])

  const t = (key) => {
    return translations[language][key] || key; // Fallback to the key if translation is missing
  };

  return (
    <I18nContext.Provider value={{ t, setLanguage, language, direction }}>
      {children}
    </I18nContext.Provider>
  );
};

export const useI18n = () => {
  return useContext(I18nContext);
};
